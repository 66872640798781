import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllWorkCenterShifts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/shift/WorkCenterShift')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    
    fetchAllShifts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/shift/Shift')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    createWorkCenterShift(ctx, workCenterShiftData) {
      return new Promise((resolve, reject) => {
        axios
          .post('pamis/admin/shift/WorkCenterShift/Create', workCenterShiftData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    updateWorkCenterShift(ctx, workCenterShiftData) {
      return new Promise((resolve, reject) => {
        axios
          .put('pamis/admin/shift/WorkCenterShift/Update', workCenterShiftData, { params: { id: workCenterShiftData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteWorkCenterShift(ctx, workCenterShiftData) {
      return new Promise((resolve, reject) => {
        axios
          .delete('pamis/admin/shift/WorkCenterShift/Delete', { params: { id: workCenterShiftData.id } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx) {
      return new Promise((resolve, reject) => {
        axios
         .get('pamis/admin/organization/Company')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSitesByCompanyId(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/Site/SitesByCompanyId/'+data.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkcentersBySiteId(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter/WorkcentersBySiteId/'+data.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkcentersByParentId(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter/WorkCentersByParentId?id='+data.id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllWorkCenters(ctx,data) {
      return new Promise((resolve, reject) => {
        axios
          .get('pamis/admin/organization/WorkCenter')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
