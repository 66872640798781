<template>
  <b-sidebar id="add-new-work-center-shift-sidebar"
             :visible="isAddNewWorkCenterShiftSidebarActive"
             bg-variant="white"
             sidebar-class="sidebar-lg"
             shadow
             backdrop
             no-header
             right
             @change="(val) => $emit('update:is-add-new-work-center-shift-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ actionType == 0 ? "Add New Work Center Shift" : actionType == 1 ? " Update Work Center Shift" :
            "Delete Work Center Shift" }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />

      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(submitForm)">

          <validation-provider #default="validationContext"
                               name="Name"
                               rules="required">
            <b-form-group label="Name"
                          label-for="full-name">
              <b-form-input id="full-name"
                            v-model="currentWorkCenterShift.name" autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :disabled="actionType == 2"
                            placeholder="Name" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group v-if="!changeWorkCenterActive && currentWorkCenterShift != null && currentWorkCenterShift.id != null"
                        label="Workcenter">
            <h5 class="mb-0"> {{ currentWorkCenterShift.workCenterName }}</h5>
            <b-button v-if="actionType != 2"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      size="sm"
                      @click="changeWorkCenter">
              Change Workcenter
            </b-button>
          </b-form-group>

          <b-form-group v-if="changeWorkCenterActive"
                        label="Company">
            <v-select
                      id="companyId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      v-model="currentCompany"
                      label="name"
                      :options="companies"
                      class="select-size-lg">
              <template #option="{ id, name }">
                <span> {{ name }}</span><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="changeWorkCenterActive"
                        label="Site">
            <v-select
                      id="siteId"
                      v-model="currentSite"
                      label="name"
                      :options="sites"
                      class="select-size-lg">
              <template #option="{ id, name }">
                <span> {{ name }}</span><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-if="changeWorkCenterActive"
                        label="Workcenter">
            <v-select
                      id="workcenterId"
                      v-model="currentWorkcenter"
                      label="name"
                      :options="workcenters"
                      class="select-size-lg "
                      :class="currentWorkCenterShift != null && currentWorkcenter != null && currentWorkCenterShift.workCenterID == currentWorkcenter.id ? 'border border-success' : null">
              <template #option="{ id, name }">
                <span> {{ name }}</span><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group v-for="item in workcenterChildEntities" :key="item.depth" label="Child Workcenter">
            <v-select
                      v-on:input="setWorkcenterChild($event, item.depth)"
                      v-model="item.selectedChild"
                      label="name"
                      :options="item.childs"
                      class="select-size-lg"
                      :class="currentWorkCenterShift != null && item.selectedChild != null && currentWorkCenterShift.workCenterID == item.selectedChild.id ? 'border border-success' : null">
              <template #option="{ id, name }">
                <span> {{ name }}</span><br />
                <small class="text-muted"> {{ id }} </small>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group
                        label="Shift"
                        label-for="shift-id">
            <h5 class="mb-0" v-if="actionType == 2"> {{ currentWorkCenterShift.shiftName }}</h5>
            <shift-picker v-if="actionType != 2"
                          :initialShifts="currentWorkCenterShift.shiftID"
                          @selectionChanged="onShiftSelectionChanged"
                          ref="shiftPickerComponent">
            </shift-picker>
          </b-form-group>

          <validation-provider #default="validationContext"
                               name="StartDate"
                               rules="required">
            <b-form-group label="StartDate"
                          label-for="start-date">
              <b-form-input id="start-date"
                            v-model="currentWorkCenterShift.startDate"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            type="date"
                            step="1"
                            :disabled="actionType == 2"
                            placeholder="Start Date" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext"
                               name="EndDate"
                               rules="required">
            <b-form-group label="EndDate"
                          label-for="end-time">
              <b-form-input id="end-time"
                            v-model="currentWorkCenterShift.endDate"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            type="date"
                            :disabled="actionType == 2"
                            step="1"
                            placeholder="End Date" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider name="Is Active">
            <b-form-group label=""
                          label-for="isActive">
              <b-form-checkbox
                               class="mt-2"
                               id="isActive"
                               :disabled="actionType == 2"
                               v-model="currentWorkCenterShift.isActive">
                Is Active
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit"
                      v-if="currentWorkCenterShift == null || currentWorkCenterShift.id == null">
              Add
            </b-button>

            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2"
                      @click="updateworkcentershift"
                      v-if="currentWorkCenterShift != null && currentWorkCenterShift.id != null && actionType == 1">
              Update
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-2"
                      v-if="currentWorkCenterShift != null && currentWorkCenterShift.id != null && actionType == 2"
                      variant="outline-danger"
                      @click="deleteworkcentershift">
              Delete
            </b-button>

            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide">
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormInvalidFeedback, BButton, BCardText
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ShiftPicker from '../components/ShiftPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BCardText,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    ShiftPicker
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewWorkCenterShiftSidebarActive',
    event: 'update:is-add-new-work-center-shift-sidebar-active',
  },
  props: {
    isAddNewWorkCenterShiftSidebarActive: {
      type: Boolean,
      required: true,
    },
    actionType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    formatterDate(value) {
        return value != null ? moment(String(value)).format('YYYY-MM-DD') : null
    },
    setWorkcenterChild(val, depth) {
      this.currentWorkCenterShift.workCenterID = null;
      if (val != null && val.id > 0) {
        this.currentWorkCenterShift.workCenterID = val.id;
        this.$store.dispatch('apps-work-center-shift/fetchWorkcentersByParentId', val)
          .then((result) => {
            var responseData = result.data;
            this.workcenterChildEntities = this.workcenterChildEntities.filter(item => !(item.depth > depth));
            if (responseData != null && responseData.length > 0) {
              var workcenterChildList = responseData.filter(x => x.isDelete == false && x.parentID != 0)
              var workcenterChildEntities = this.workcenterChildEntities;

              if (workcenterChildList != null && workcenterChildList.length > 0) {
                this.workcenterChildEntities.push({ childs: workcenterChildList, depth: workcenterChildEntities.length + 1 })
              }
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
      else {
        if (depth > 1) {
          this.currentWorkCenterShift.workCenterID = this.workcenterChildEntities.find(item => item.depth == depth - 1).selectedChild.id;
        }
        else {
          this.currentWorkCenterShift.workCenterID = this.currentWorkcenter.id;
        }
        this.workcenterChildEntities = this.workcenterChildEntities.filter(item => !(item.depth > depth));
      }
    },
    fetchCompanies() {
      this.companies = [];
      this.currentCompany = null;
      this.sites = []
      this.currentSite = null
      this.workcenters = [];
      this.currentWorkcenter = null
      this.workcenterChildEntities = [];
      this.$store.dispatch('apps-work-center-shift/fetchCompanies')
        .then((result) => {
          console.log(result)
          if (result != null) {

            var companyList = result.data.filter(x => x.isDelete == false)
            this.companies = companyList
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Get Companies By Client Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    onShiftSelectionChanged(item) {
      this.currentWorkCenterShift.shiftID = (item != null && item.id != null) ? item.id : 0;
    },
    deleteworkcentershift() {
      this.$store.dispatch('apps-work-center-shift/deleteWorkCenterShift', this.currentWorkCenterShift)
        .then((result) => {
          this.$emit('refetch-data')
          this.$emit('update:is-add-new-work-center-shift-sidebar-active', false)
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Delete Work Center Shift Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        });
    },
    updateworkcentershift() {
      if (this.currentWorkCenterShift.shiftID == null || this.currentWorkCenterShift.shiftID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Shift Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentWorkCenterShift.workCenterID == null || this.currentWorkCenterShift.workCenterID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'WorkCenter can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.$store.dispatch('apps-work-center-shift/updateWorkCenterShift', this.currentWorkCenterShift)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-work-center-shift-sidebar-active', false)
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Update Work Center Shift Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    },
    changeWorkCenter() {
      this.currentWorkCenterShift.workCenterID = null;
      this.changeWorkCenterActive = true;
    },
    submitForm() {
      if (this.currentWorkCenterShift.shiftID == null || this.currentWorkCenterShift.shiftID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Shift Field can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else if (this.currentWorkCenterShift.workCenterID == null || this.currentWorkCenterShift.workCenterID < 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'WorkCenter can not be null',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      else {
        this.$store.dispatch('apps-work-center-shift/createWorkCenterShift', this.currentWorkCenterShift)
          .then((result) => {
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-work-center-shift-sidebar-active', false)
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Create Work Center Shift Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
    }
  },

  watch: {
    currentCompany(val) {
      this.currentWorkCenterShift.workCenterID = null;
      if (val != null && val.id > 0) {
        this.$store.dispatch('apps-work-center-shift/fetchSitesByCompanyId', val)
          .then((result) => {
            console.log(result.data)
            if (result.data != null) {
              this.sites = []
              this.currentSite = null
              this.workcenters = [];
              this.currentWorkcenter = null
              this.workcenterChildEntities = [];
              var siteList = result.data.filter(x => x.isDelete == false)
              this.sites = siteList
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Sites By Company Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
      else {
        this.currentSite = null
        this.workcenters = [];
        this.currentWorkcenter = null
        this.workcenterChildEntities = [];
      }
    },
    currentSite(val) {
      this.currentWorkCenterShift.workCenterID = null;
      if (val != null && val.id > 0) {
        this.$store.dispatch('apps-work-center-shift/fetchWorkcentersBySiteId', val)
          .then((result) => {
            console.log(result)
            if (result != null) {
              this.workcenters = [];
              this.currentWorkcenter = null;
              this.workcenterChildEntities = [];
              var workcenterList = result.data.filter(x => x.isDelete == false && x.parentID == 0)
              this.workcenters = workcenterList
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
      else {
        this.workcenters = [];
        this.currentWorkcenter = null;
        this.workcenterChildEntities = [];
      }

    },
    currentWorkcenter(val) {
      this.currentWorkCenterShift.workCenterID = null;
      if (val != null && val.id > 0) {
        this.currentWorkCenterShift.workCenterID = val.id;
        this.$store.dispatch('apps-work-center-shift/fetchWorkcentersByParentId', val)
          .then((result) => {
            if (result != null) {
              this.workcenterChildEntities = [];
              var workcenterChildList = result.data.filter(x => x.isDelete == false && x.parentID != 0)

              if (workcenterChildList != null && workcenterChildList.length > 0) {
                this.workcenterChildEntities.push({ childs: workcenterChildList, depth: 1 })
              }
            }
          })
          .catch((error) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Workcenters By Site Error',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          });
      }
      else
        this.workcenterChildEntities = [];
    },
    workcentershiftdata: {
      handler: function (n, o) {
        if (n == null || n == undefined) {
          this.currentWorkCenterShift = {
            name: null,
            startDate: null,
            endDate: null,
            workCenterID: null,
            isActive: null,
          }
        } else {
          this.currentWorkCenterShift = n.selectedWorkCenterShift;
          this.currentWorkCenterShift.startDate = this.formatterDate(this.currentWorkCenterShift.startDate);
          this.currentWorkCenterShift.endDate = this.formatterDate(this.currentWorkCenterShift.endDate);
        }
      },
      deep: true
    }

  },
  created() {
    this.fetchCompanies();
  },
  data() {
    return {
      required,
      currentWorkCenterShift: null,
      currentCompany: null,
      companies: [],
      currentSite: null,
      sites: [],
      currentWorkcenter: null,
      workcenters: [],
      workcenterChildEntities: [],
      changeWorkCenterActive: false
    }
  },
  inject: ['workcentershiftdata'],
  setup(props, { emit }) {
    const blankWorkCenterShiftData = {
      name: null,
      startDate: null,
      endDate: null,
      workCenterID: null,
      isActive: null,
    }
    const workCenterShiftData = ref(JSON.parse(JSON.stringify(blankWorkCenterShiftData)))
    const {
      getValidationState,
    } = formValidation(workCenterShiftData)

    return {
      workCenterShiftData,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-work-center-shift-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
