import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useWorkCenterShiftList() {
  // Use toast
  const toast = useToast()


  // Table Handlers
  const tableColumns = [
    { key: 'id', label: "ID", sortable: true },
    { key: 'name', label: "Name", sortable: true },
    // { key: 'workCenterID', label: "Work Center", sortable: true },
    { key: 'workCenterName', label: "Work Center", sortable: true },
    { key: 'startDate', label: "Start Date", sortable: true },
    { key: 'endDate', label: "End Date", sortable: true },
    { key: 'shiftName', label: "Shift", sortable: true },
    { key: 'isActive', label: "is Active", sortable: true },
    { key: 'actions' },
  ]
  const totalWorkCenterShifts = ref(0)
  const tableItems = ref([])
  const refWorkCenterShiftListTable = ref(null)
  const isSortDirDesc = ref(true)

  const refetchData = () => {
    store
      .dispatch('apps-work-center-shift/fetchAllWorkCenterShifts', {
      })
      .then(response => {
        response.data = response.data.filter(x => !x.isDelete);
        // tableItems.value = response.data;
        // totalWorkCenterShifts.value = response.data.length;
        store
          .dispatch('apps-work-center-shift/fetchAllShifts', {
          })
          .then(response2 => {
            response2.data = response2.data.filter(x => !x.isDelete);
            response.data.forEach(item => {
              item.shiftName = '';
              if (item.shiftID != 0 && response2.data.find(x => x.id == item.shiftID) != null) {
                item.shiftName = response2.data.find(x => x.id == item.shiftID).name;
              }
            });
            store
              .dispatch('apps-work-center-shift/fetchAllWorkCenters', {
              })
              .then(response2 => {
                response2.data = response2.data.filter(x => !x.isDelete);
                response.data.forEach(item => {
                  item.workCenterName = '';
                  if (item.workCenterID != 0 && response2.data.find(x => x.id == item.workCenterID) != null) {
                    item.workCenterName = response2.data.find(x => x.id == item.workCenterID).name;
                  }
                });
                tableItems.value = response.data;
                totalWorkCenterShifts.value = response.data.length;
              })
              .catch(error => reject(error));
          })
          .catch(error => reject(error));
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching work center shift list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  refetchData();
  return {
    refetchData,
    tableColumns,
    tableItems,
    isSortDirDesc,
    totalWorkCenterShift: totalWorkCenterShifts,
    refWorkCenterShiftListTable
  }
}
